<script setup lang="ts">
    import { checkStars } from '~/utils/stamped';

    interface StampedStarsProps {
        to?: string;
        dataId?: string;
    }
    defineProps<StampedStarsProps>();

    const { isUS } = useLocales();

    const stampedStarsContainer = ref<HTMLElement>();

    onMounted(async () => {
        // Init and insert Stamped.io star rating
        if (isUS.value) {
            await stampedInit();

            if (stampedStarsContainer.value) {
                await checkStars(stampedStarsContainer.value);
            }
        }
    });
</script>

<template>
    <client-only>
        <div
            v-if="isUS"
            ref="stampedStarsContainer"
            class="atm-stamped-stars">
            <nuxt-link
                :to="to"
                class="stamped-product-reviews-badge stamped-main-badge underline"
                :data-id="dataId" />
        </div>
    </client-only>
</template>
